import React from 'react';

import styles from './FeatureBox.module.scss';

const FeatureBox = ({ id, title, content, image }) => {
    return (
        <div key={id} className={styles.featureBoxContainer}>
            <div className={styles.imageContainer}>
                <img style={{ width: "90px", height: "100%" }} src={image} alt='' />
            </div>
            <div className={styles.textContainer}>
                <span className={styles.title}>{title}</span>
                <span className={styles.content}>{content}</span>
            </div>
        </div>
    )
}

export default FeatureBox;