import React from 'react';
import productGroupImage1 from '../../static/ev-tipi.jpg';
import productGroupImage2 from '../../static/profesyonel.jpg';
import productGroupImage3 from '../../static/astarlı.jpg';
import productGroupImage4 from '../../static/buzgulu.jpg';
import productGroupImage5 from '../../static/medikal.jpg';
import ProductBox from '../../components/productBox/ProductBox';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { useNavigate } from 'react-router-dom';

import styles from './ProductsPage.module.scss';

const ProductsPage = () => {

    const navigate = useNavigate();

    const productBoxDatas = [
        { id: 0, title: "EV TİPİ", subTitle: "Çöp Poşetleri", image: productGroupImage1, url: '/urunler/ev-tipi-cop-posetleri' },
        { id: 1, title: "PROFESYONEL", subTitle: "Çöp Poşetleri", image: productGroupImage2, url: '/urunler/profesyonel-cop-posetleri' },
        { id: 2, title: "ASTARLI", subTitle: "Çöp Poşetleri", image: productGroupImage3, url: '/urunler/astarli-cop-posetleri' },
        { id: 3, title: "BÜZGÜLÜ", subTitle: "Çöp Poşetleri", image: productGroupImage4, url: '/urunler/buzgulu-cop-posetleri' },
        { id: 4, title: "MEDİKAL", subTitle: "Çöp Poşetleri", image: productGroupImage5, url: '/urunler/medikal-cop-posetleri' },
    ]

    return (
        <div className={styles.productsPageContainer}>
            <div className={styles.breadcrumbContainer}>
                <div className={styles.breadcrumbMainContainer}>
                    <span onClick={() => navigate(`/`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Anasayfa</span>
                    <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                    <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>ÜRÜNLER</span>
                </div>
            </div>
            <div className={styles.pageTitleContainer}>
                <h1>ÜRÜNLER</h1>
            </div>
            <div className={styles.productsContainer}>
                <div className={styles.productsMainContainer}>
                    { 
                        productBoxDatas.map(val => ( 
                            <ProductBox 
                                key={val.id} 
                                title={val.title} 
                                subTitle={val.subTitle} 
                                image={val.image} 
                                url={val.url} 
                            /> 
                        )) 
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductsPage;