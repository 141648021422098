import React from 'react';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { useNavigate } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ category, title }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.breadcrumbContainer}>
            <div className={styles.breadcrumbMainContainer}>
                <span onClick={() => navigate(`/urunler`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }} >Urunler</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>{`${category} - ${title}`}</span>
            </div>
        </div>
    )
}

export default Breadcrumb