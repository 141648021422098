import React from 'react';

import styles from './FooterBottom.module.scss';
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from '../../utils/IconUtils';

const FooterBottom = () => {
    return (
        <div className={styles.footerBottomContainer}>
            <div className={styles.footerBottomMainContainer}>
                <div className={styles.footerTextContainer}>
                    <span>© 2024 ZERPAK - Tüm Hakları Saklıdır.</span>
                </div>
                <div className={styles.footerIconContainer}>
                    <FacebookIcon />
                    <TwitterIcon />
                    <LinkedinIcon />
                    <InstagramIcon />
                </div>
            </div>
        </div>
    )
}

export default FooterBottom