import React from 'react';
import StatisticsSection from '../../components/statisticsSection/StatisticsSection';
import bossImage from '../../static/kazim-zer.jpg'

import styles from './InstitutionalPage.module.scss';

const InstitutionalPage = () => {

    return (
        <div className={styles.institutionalPageContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.backgroundBlack}></div>
                <div className={styles.headerMainContainer}>
                    <h1>Kurumsal</h1>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.topContainer}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>HAKKIMIZDA</h1>
                            <p>Zerpak Ambalaj 2011 yılında İstanbul Kıraç'da endüstriyel atık çöp torbası ihtiyacını karşılamak amacı ile kurulmuştur. Yıllar içinde yapmış olduğu marka ve makine yatırımları ile bugün ağırlıklı olarak çöp torbası, mop ve koli bandı üretiminde faaliyet göstermektedir. Zerpak Ambalaj olarak çevre sorumluluğu bilinci birinci önceliğimizdir. Bu bağlamda şirketimiz; güvenilirlik, esneklik, yenilikçilik ve sürdürülebilirlik gibi temel değerleri ile müşterilerine özel çözümler sunmaktadır.</p>
                            <p>İstanbul Kıraç’ta, 1600 metrekarelik kapalı alanda 2011 yılında kurulan Zerpak Ambalaj bugün Hadımköy fabrikada, 5500 metrekare kapalı alanda 100'ü aşkın personeli ile ayda 450 tonun üzerinde üretim yapmakta olup, ürünlerinin yurtiçi ve yurtdışındaki tüketicileri ile buluşmasını sağlamaktadır.</p>
                            <p>Üretiminin %35’ini bölge ülkelerine ve ABD'ye ihraç ederken, yakın gelecekte ihracat payının arttırılmasını planlamaktadır. Zerpak Ambalaj üretici kimliğinin dışında Star Plus, Star Home ve Vioraplast markalarının üretim, satış, lojistik hizmetlerini de vermekte olup, değerli markalama ve markalaşma yolunda vizyoner adımlarla ilerlemektedir. Zerpak Ambalaj bir grup şirketidir. Gıda dışı toptan ve toptan üstü satış kanalında lider pozisyonda bulunan Zergrup ailesinin üretici üyesidir.</p>
                            <p>Firmamız siz değerli müşterilerimizi yüksek düzeyde memnun etmek, beklentilerinizi en ekonomik ve hızlı şekilde karşılamak için sürekli gelişim içerisindedir. Alanında kalifiye uzman personel kadrosuna hakim olan Zerpak Ambalaj üretim sorumluluğunun her daim farkındadır. Her şeyden önce, en iyi ve hızlı hizmeti vermeye çalışıp uzun yıllar sürecek çözüm odaklı bir firma olmaya büyük önem veriyoruz. Aslında kısacası “Bahane değil çözüm üretiyoruz.” Disipline ve Disiplinli çalışmanın gerekliliğine inanıyoruz. Zerpak Ambalaj’ı farklı kılan tüm özellikler tabiki bunlar değil, bizi daha yakından tanıdığınızda neden farklı olduğumuzu çok daha iyi anlayacaksınız.</p>
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>GRUP ŞİRKETLERİ</h1>
                            <h4 style={{marginTop: "12px"}}>ZERSAN LÜKS HIRDAVAT KIRTASİYE PLASTİK SANAYİ VE DIŞ TİCARET LİMİTED ŞİRKETİ</h4>
                            <p>Bünyesinde bulunan; lüks hırdavat, plastik banyo ve mutfak ürünleri, oyuncak, kırtasiye malzemeleri, ev ve mutfak gereçleri, temizlik malzemeleri, elektrik ve elektronik ürünler, kozmetik, hediyelik eşya, aydınlatma gibi ürünlerin alımını, ithalini, ihracını ve ticaretini yapmaktadır. Bünyesinde yer alan markalarıyla oluşturduğu ürünleri yurtiçinde tüketici ile buluşmasını sağlıyor.</p>
                            <h4 style={{marginTop: "16px"}}>ZERAS OTOMOTİV LÜKS HIRDAVAT SANAYİ DIŞ TİCARET LİMİTED ŞİRKETİ</h4>
                            <p>Bünyesinde bulunan; lüks hırdavat, plastik banyo ve mutfak ürünleri, oyuncak, kırtasiye malzemeleri, ev ve mutfak gereçleri, temizlik malzemeleri, elektrik ve elektronik ürünler, kozmetik, hediyelik eşya, aydınlatma gibi ürünlerin alımını, ithalini, ihracını ve ticaretini yapmaktadır. Bünyesinde yer alan markalarıyla oluşturduğu ürünleri yurtiçinde tüketici ile buluşmasını sağlıyor.</p>
                            <h4 style={{marginTop: "16px"}}>ZERPLAST PLASTİK EV GEREÇLERİ SANAYİ VE TİCARET ANONİM ŞİRKETİ</h4>
                            <p>Zerplast Plastik, 3000 m² kapalı alanda ileri teknoloji makine parkuru ile plastik banyo, ev gereçleri, temizlik ürünleri üretimi yapmaktadır. Yurtiçi ve yurtdışı pazarlarda yaygın bulunurluk sağlamak, üretimde kalite ve hizmette devamlılık sağlamak, müşterileri, iş ortakları ve çalışanları için katma değer yaratmak ilkeleriyle çalışmalarına devam etmektedir.</p>
                            <h4 style={{marginTop: "16px"}}>ZERAY GROUP LLC LIMITED LIABILITY COMPANY</h4>
                            <p>Yurtdışında faliyet gösteren şirketimiz yeme-içme ve restaurant hizmetleri vermektedir.</p>
                        </div>
                        
                    </div>
                    <div className={styles.bottomContainer}>
                    <div style={{ width: "100%", height: "100%" }}>
                            <img  style={{ width: "100%", height: "100%" }} src={bossImage} alt="" />
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>ÜLKEMİZ İÇİN HERZAMAN İLERİ...</h1>
                            <p>Bizler, kaliteli üretim ve müşteri memnuniyeti konusundaki taahhüdümüzü sizlere en iyi şekilde yansıtmak için var gücümüzle çalışıyoruz.</p>
                            <p>Sizin için en iyiyi sunma misyonumuzun temelinde güven yatmaktadır. Fabrikamız, başlangıcından itibaren müşterilerimize olan güveni en üst seviyede tutmayı ilke edinmiştir. Bu güveni sağlamak için kalite, memnuniyet, saygı ve inovasyon misyonlarına bağlı çalışmalarımızı var gücümüzle sürdürüyoruz.</p>
                            <p>Bizimle çalıştığınızda, güvenilir bir iş ortağı bulduğunuzdan emin olabilirsiniz. Müşteri memnuniyeti ve kalite standartlarımızla, sizlere en iyi hizmeti sunmak için buradayız.</p>
                            <p>Sizlere destek olmaktan ve iş birliği yapmaktan mutluluk duyarız.</p>
                            <p>Saygılarımla,</p>
                            <h5>KAZIM ZER</h5>
                            <p>Kurucu</p>
                        </div>
                    </div>

                </div>
            </div>
            <StatisticsSection />
        </div>
    )
}

export default InstitutionalPage;