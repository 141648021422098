import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import HeaderTop from './components/headerTop/HeaderTop'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import FooterBottom from './components/footerBottom/FooterBottom'
import Loading from './components/loading/Loading'

const SANLayout = () => {

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1200);
        
    },[pathname])

    if(loading) return <Loading />

    return (
        <>
            <HeaderTop />
            <Header />
            <Outlet />
            <Footer />
            <FooterBottom />
        </>
    )
}

export default SANLayout