import React from 'react';
import featureIcon from '../../static/feature-box-icon.png';

import styles from './StatisticsSection.module.scss';
import { LocationIcon } from '../../utils/IconUtils';

const StatisticsSection = () => {
    return (
        <div className={styles.statisticsSectionContainer}>
            <div className={styles.statisticsSectionMainContainer}>
                <div className={styles.statisticsBox}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LocationIcon style={{ width: "50px", height: "50px" }} color={"#c1d100"} />
                    </div>
                    <span className={styles.count}>2750</span>
                    <span className={styles.title}>MUTLU MÜŞTERİ</span>
                </div>
                <div className={styles.statisticsBox}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LocationIcon style={{ width: "50px", height: "50px" }} color={"#c1d100"} />
                    </div>
                    <span className={styles.count}>22</span>
                    <span className={styles.title}>ÜLKEYE İHRACAT</span>
                </div>
                <div className={styles.statisticsBox}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LocationIcon style={{ width: "50px", height: "50px" }} color={"#c1d100"} />
                    </div>
                    <span className={styles.count}>230</span>
                    <span className={styles.title}>ÜRÜN GAMI</span>
                </div>
                <div className={styles.statisticsBox}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LocationIcon style={{ width: "50px", height: "50px" }} color={"#c1d100"} />
                    </div>
                    <span className={styles.count}>350</span>
                    <span className={styles.title}>PERSONEL</span>
                </div>
            </div>
        </div>
    )
}

export default StatisticsSection;