import React from 'react';

import styles from './Loading.module.scss';

const Loading = ({ height }) => {
    return (
        <div style={{height: height ?? "600px"}} className={styles.loaderContainer}>
            <span className={styles.loader}></span>
        </div>
    )
}

export default Loading;