import React from 'react';
import { ArrowRightLongIcon } from '../../utils/IconUtils';
import { Link, useNavigate } from 'react-router-dom';

import styles from './ProductBoxWithDetail.module.scss';

const ProductBoxWithDetail = ({ name, image, data }) => {

    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/urun-detay/${name}/${data.no}`)} className={styles.productBoxContainer}>
            <div className={styles.imageContainer}>
                <img src={image} alt="" />
            </div>
            <div className={styles.contentContainer}>
                <div>
                    <span className={styles.category}>{`${data.category}`}</span>
                </div>
                <div className={styles.titleContainer}>
                    <div className={styles.numberContainer}>
                        <span className={styles.title}>{`${data.no}`}</span>
                    </div>
                    <span className={styles.title}>{` | ${data.title}`}</span>
                </div>
                {/* <div className={styles.detailContainer}>
                    <span><strong>1 Ürün:</strong> {data.product}</span>
                    <span><strong>1 Rulo:</strong> {data.roll}</span>
                    <span><strong>1 Koli:</strong> {data.parcel}</span>
                    <span><strong>1 Palet:</strong> {data.palette}</span>
                </div> */}
                <div className={styles.linkContainer}>
                    <Link className={styles.item} to={"#"}>
                        Detaylı İncele
                    </Link>
                    <ArrowRightLongIcon />
                </div>
            </div>
        </div>
    )
}

export default ProductBoxWithDetail;