import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import image1 from '../../static/1.png'
import image2 from '../../static/2.jpg'
import image3 from '../../static/3.jpg'
import propertyImage from '../../static/product-property.png'
import productBPA from '../../static/product-bpa.jpg'
import productImage1 from '../../static/urunler/ev-tipi-mini-boy.jpg';
import productImage2 from '../../static/urunler/ev-tipi-orta-boy.jpg';
import productImage3 from '../../static/urunler/ev-tipi-buyuk-boy.jpg';
import productImage4 from '../../static/urunler/ev-tipi-battal-boy.jpg';
import productImage5 from '../../static/urunler/ev-tipi-jumbo-boy.jpg';

import styles from './ProductDetailPage.module.scss';
import { useParams } from 'react-router-dom';

const ProductDetailPage = () => {

    const { name, id } = useParams();
    const [activeImage, setActiveImage] = useState(1);
    const [productDetailData, setProductDetailData] = useState({});

    const productDatas = [
        {
            id: "ev-tipi-cop-posetleri",
            data: [
                {
                    no: 101,
                    category: "EV TİPİ",
                    title: "MİNİ BOY",
                    product: "40x50 cm - 5 mikron",
                    roll: "80 gram - 40 torba",
                    parcel: "50 rulo - 0,00122 m³",
                    palette: "180 koli",
                    img: productImage1
                },
                {
                    no: 102,
                    category: "EV TİPİ",
                    title: "ORTA BOY",
                    product: "55x60 cm - 5 mikron",
                    roll: "60 gram - 20 torba",
                    parcel: "50 rulo - 0,0131 m³",
                    palette: "180 koli",
                    img: productImage2
                },
                {
                    no: 104,
                    category: "EV TİPİ",
                    title: "BÜYÜK BOY",
                    product: "65x80 cm - 6 mikron",
                    roll: "60 gram - 10 torba",
                    parcel: "50 rulo - 0,0117 m³",
                    palette: "180 koli",
                    img: productImage3
                },
                {
                    no: 106,
                    category: "EV TİPİ",
                    title: "BATTAL BOY",
                    product: "75x90 cm - 11 mikron",
                    roll: "140 gram - 10 torba",
                    parcel: "50 rulo - 0,0211 m³",
                    palette: "120 koli",
                    img: productImage4
                },
                {
                    no: 108,
                    category: "EV TİPİ",
                    title: "JUMBO BOY",
                    product: "80x110 cm - 12 mikron",
                    roll: "200 gram - 10 torba",
                    parcel: "25 rulo - 0,0146 m³",
                    palette: "144 koli",
                    img: productImage5
                },
            ]
        },
    ]

    const getImage = (count) => {
        switch (count) {
            case 1:
                return image1;
            case 2:
                return image2;
            case 3:
                return image3;
            default:
              return image1
        }
    }

    useEffect(() => {
        productDatas.map((value) => {
            if(value.id == name){
                value.data.map(val => {
                    if(val.no == id){
                        setProductDetailData(val);
                    }
                })
            }
        })
    },[])

    return (
        <div className={styles.productDetailPage}>
            <Breadcrumb category={productDetailData.category} title={productDetailData.title} />
            <div className={styles.productContainer}>
                <div className={styles.productMainContainer}>
                    <div className={styles.leftContainer}>
                        {/* <div onClick={() => setActiveImage(1)} className={activeImage === 1 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={image1} alt="" />
                        </div>
                        <div onClick={() => setActiveImage(2)} className={activeImage === 2 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={image2} alt="" />
                        </div>
                        <div onClick={() => setActiveImage(3)} className={activeImage === 3 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={image3} alt="" />
                        </div> */}
                        <div onClick={() => setActiveImage(1)} className={activeImage === 1 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={productDetailData.img} alt="" />
                        </div>
                    </div>
                    <div className={styles.mainContainer}>
                        <div className={styles.mainImageContainer}>
                            {/* <img style={{ width: "100%", height: "100%" }} src={getImage(activeImage)} alt="" /> */}
                            <img style={{ width: "100%", height: "100%" }} src={productDetailData.img} alt="" />
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.properyContainer}>
                            <h2>{productDetailData.category}</h2>
                            <h1>{productDetailData.title}</h1>
                        </div>
                        {/* <div className={styles.properyContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={productBPA} alt="" />
                        </div> */}
                        <div className={styles.properyContainer}>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>ÜRÜN</h3>
                                    <h4>{productDetailData.product}</h4>
                                </div>
                            </div>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>RULO</h3>
                                    <h4>{productDetailData.roll}</h4>
                                </div>
                            </div>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>KOLİ</h3>
                                    <h4>{productDetailData.parcel}</h4>
                                </div>
                            </div>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>PALET</h3>
                                    <h4>{productDetailData.palette}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailPage