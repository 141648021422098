import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Menu.module.scss';

const Menu = ({ data }) => {

    return (
        <div className={styles.menuContainer}>
            {
                data.map((val) => (
                    <div key={val.id} className={styles.menuItemContainer}>
                        <Link style={{ textDecoration: "none" }} className={styles.item} to={val.href}>
                            {val.title}
                        </Link>
                    </div>
                ))
            }
        </div>
    )
}

export default Menu