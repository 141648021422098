import React, { useState } from 'react';
import { ArrowRightLongIcon, PlusIcon } from '../../utils/IconUtils';
import { Link, useNavigate } from 'react-router-dom';

import styles from './ProductBox.module.scss';

const ProductBox = ({ title, subTitle, image, url }) => {

    const [hover, setHover] = useState(false);
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(url)} className={styles.productBoxContainer}>
            <div className={styles.contentContainer}>
                <span className={styles.subTitle}>{subTitle}</span>
                <span className={styles.title}>{title}</span>
            </div>
            <div className={styles.imageContainer}>
                <img src={image} alt="" />
            </div>
            <div className={styles.plusContainer}>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={styles.plusMainContainer}>
                    <div className={styles.iconContainer}>
                        <PlusIcon color={hover ? "#fff" : "rgba(0, 0, 0, 0.2)"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductBox;