import React from 'react';
import AppRoutes from './AppRoutes';

import './App.scss';

const App = () => {
  return (
    <AppRoutes />
  )
}

export default App