import React from 'react';

import styles from './HeaderTop.module.scss';
import { FacebookIcon, InstagramIcon, LinkedinIcon, MailIcon, PhoneIcon, TwitterIcon } from '../../utils/IconUtils';
import { Link } from 'react-router-dom';

const HeaderTop = () => {
    return (
        <div className={styles.headerTopContainer}>
            <div className={styles.mainContainer}>
                <div className={styles.leftContainer}>
                    <span>Lorem ipsum dolor, sit amet</span>
                    <div className={styles.infoContainer}>
                        <PhoneIcon />
                        <Link style={{ marginRight: "6px" }} className={styles.item} to={"tel:+902128868545"}>
                            +90 212 886 85 45
                        </Link>
                        <MailIcon />
                        <Link className={styles.item} to={"mailto:info@zerpak.com.tr"}>
                            info@zerpak.com.tr
                        </Link>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <FacebookIcon style={{ cursor: "pointer" }} />
                    <TwitterIcon style={{ cursor: "pointer" }} />
                    <LinkedinIcon style={{ cursor: "pointer" }} />
                    <InstagramIcon style={{ cursor: "pointer" }} />
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;